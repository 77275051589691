import https from "./https"
import store from "@/store"

const organization = store.state.general.organization

const rewardGiftApi = {
  list(rewardCardId, params) {
    return https.get(`admin/organizations/${organization.id}/reward-cards/${rewardCardId}/reward-gifts`, {params})
  },
  get(rewardCardId, rewardGiftId) {
    return https.get(`admin/organizations/${organization.id}/reward-cards/${rewardCardId}/reward-gifts/${rewardGiftId}`)
  },
  store (rewardCardId, params) {
    return https.post(`admin/organizations/${organization.id}/reward-cards/${rewardCardId}/reward-gifts`, params)
  },
  updateAll(rewardCardId, params) {
    return https.put(`admin/organizations/${organization.id}/reward-cards/${rewardCardId}/reward-gifts`, params)
  },
  remove(rewardCardId, rewardGiftId) {
    return https.delete(`admin/organizations/${organization.id}/reward-cards/${rewardCardId}/reward-gifts/${rewardGiftId}`)
  },
}

export default rewardGiftApi
