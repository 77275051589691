<template>
  <b-modal
    id="modal-search-product"
    title="選擇商品"
    @show="fetchProducts"
  >
    <b-input-group>
      <b-form-input placeholder="請輸入商品名稱" v-model="query.keyword" @keyup.enter="fetchProducts"></b-form-input>
      <b-input-group-append>
        <b-button @click="fetchProducts"><i class="fa fa-search"></i></b-button>
      </b-input-group-append>
    </b-input-group>

    <div class="text-center mt-3 mb-3 loading-spinner" v-if="isLoading">
      <b-spinner variant="secondary" small />
    </div>
    <div class="mt-3 mb-3" v-else-if="products.length > 0">
      <div
        v-for="product in products"
        v-bind:key="product.id"
        class="d-flex align-items-center py-2 product-item"
        @click="handleSelected(product)"
      >
        <template>
          <b-img-lazy
            v-if="deepGet(product, 'images[0].pic_url')"
            class="mx-2 thumb-image flex-shrink-0"
            :src="deepGet(product, 'images[0].pic_url')"
            alt="商品圖片"
            width="50"
            height="25"
          />
          <div v-else class="mx-2 flex-shrink-0" :style="{ width: '50px', height: '25px'}"></div>
        </template>
        <div class="mr-auto text-nowrap text-truncate">
          <div class="font-weight-bold">
            {{ product.title }}
          </div>
          <small class="text-muted">
            {{ format(new Date(product.available_start_at), 'yyyy-MM-dd HH:mm') }} ~ {{ format(new Date(product.available_end_at), 'yyyy-MM-dd HH:mm') }}
          </small>
        </div>
        <div class="mx-2">
          <span v-if="selectedProductsIdList.includes(product.id)" class="mdi mdi-check-circle mdi-24px" />
        </div>
      </div>
    </div>

    <template #modal-footer>
      <div class="w-100">
        <div class="float-right">
          <span
            class="mr-2 mdi mdi-chevron-left mdi-36px"
            :class="meta.current_page == 1 ? 'text-muted': ''"
            @click="prevPage">
          </span>
          <span
            class="mr-5 mdi mdi-chevron-right mdi-36px"
            :class="meta.current_page == meta.last_page ? 'text-muted': ''"
            @click="nextPage">
          </span>

          <b-button
            variant="danger"
            size="sm"
            class="mr-2 ml-5"
            @click="closeModal"
          >
            取消
          </b-button>
          <b-button
            variant="primary"
            size="sm"
            @click="submitSelectedProducts"
          >
            確定
          </b-button>
        </div>
      </div>
    </template>
  </b-modal>
</template>

<script>
  import { format } from "date-fns";
  import productApi from "@/apis/product";
  import deepGet from "@/utils/deepGet";

  export default {
    props: {
      merchantId: {
        type: String,
      },
      selectedProducts: {
        type: Array,
      },
    },

    computed: {
      selectedProductsIdList() {
        return this.selectedProductsData?.map((product) => product.id) ?? []
      }
    },
    data() {
      return {
        isLoading: false,
        products: [],
        selectedProductsData: [],
        meta: {},
        query: {
          page: 1,
          per_page: 10,
          create_type: "0",
          keyword: "",
        },
      }
    },

    watch: {
      "query.keyword"() {
        this.query.page = 1
      },
      selectedProducts() {
        this.selectedProductsData = this.selectedProducts
      },
    },

    methods: {
      deepGet,
      format,
      async fetchProducts() {
        try {
          this.isLoading = true;
          const params = {
            ...this.query,
            merchant_id: this.merchantId,
          };
          const { data } = await productApi.getProducts(params);
          this.meta = data.meta;
          this.products = data.data;
        } catch (error) {
          console.log(error);
        }
        this.isLoading = false;
      },
      handleSelected(product) {
        const index = this.selectedProductsData.findIndex(({ id }) => id === product.id);

        if (index === -1) {
          this.selectedProductsData.push(product);
        } else {
          this.selectedProductsData.splice(index, 1);
        }
      },
      submitSelectedProducts() {
        this.$bvModal.hide('modal-search-product')
        this.$emit('selected', this.selectedProductsData)
      },
      closeModal() {
        this.$bvModal.hide('modal-search-product')
      },
      prevPage() {
        if (this.meta.current_page == 1) {
          return
        }
        this.query.page -= 1;
        this.fetchProducts();
      },
      nextPage() {
        if (this.meta.current_page == this.meta.last_page) {
          return
        }
        this.query.page += 1;
        this.fetchProducts();
      }
    }
  }
</script>

<style lang="scss">
.product-item:hover {
  cursor: pointer;
  background-color: #c7c7c7;
  color: white;
  width: 100%;

  .staff-list-info {
    color: white;
  }

}
</style>
